/*//*/ /*/ Colors /*/ /*//*/
/*
// TODO: you can update all or some of these colors and their declarations throughout the app, but mainly the primary and secondary colors likely

Blue: #4FBFE0
Dark: #3B8FA8
Light: #72CCE6
Xlight: #B9E5F3
xxlight: #DCF2F9

Yellow: #FDBB30
Dark: #BE8C24
Light: #FDC959
Xlight: #FEE4AC
xxlight: #FFF1D6

Pink: #F9BFBC
Dark: #BB8F8D
Light: #FBD9D7
Xlight: #FEF2F2

Red: #E3352B
Dark: #AA2820
Light: #EE8680
Xlight: #F9D7D5

Orange: #FF8533
Dark: #B35026
Light: #FFB685
Xlight: #FFE7D6

Green: #33CC00
Dark: #269900
Light: #8FC981
Xlight: #D6F5CC

Grey: #A1A1A1
Dark: #6B6B6B
Light: #D6D6D6

Font: Roboto

*/
/*//*//*//*//*//*//*//*/
@font-face {
    font-family: "Roboto-Thin";
    src: url(../fonts/Roboto-Light.ttf);
}
@font-face {
    font-family: "Roboto-Light";
    src: url(../fonts/Roboto-Light.ttf);
}
@font-face {
    font-family: "Roboto-Regular";
    src: url(../fonts/Roboto-Regular.ttf);
}
@font-face {
    font-family: "Roboto-Medium";
    src: url(../fonts/Roboto-Medium.ttf);
}
@font-face {
    font-family: "Roboto-Italic";
    src: url(../fonts/Roboto-Italic.ttf);
}
@font-face {
    font-family: "Roboto-Bold";
    src: url(../fonts/Roboto-Bold.ttf);
}

/* 
* Heading
*/
h1 {
    color: white;
    font-size: 3em;
    font-family: "Roboto-Bold";
}

h2 {
    color: white;
    font-size: 2.5em;
    font-family: "Roboto-Bold";
}

h3 {
    color: white;
    font-size: 1.5em;
    padding: 0px !important;
    margin: 0px !important;
    font-family: "Roboto-Bold";
    word-wrap: break-word !important;
    white-space: normal !important;
}

@media screen and (max-width: 900px) {
    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.6em;
    }
    h3 {
        font-size: 1em;
    }
}

h4 {
    font-family: "Roboto-Bold";
    color: white;
}

h5 {
    font-family: "Roboto-Bold";
    color: white;
}

/* 
* Body
*/
p {
    font-family: "Roboto-Medium" !important;
    line-height: 1.6;
    font-size: 16;
    color: white;
}

a {
    text-decoration: none;
    font-family: "Roboto-Medium";
    cursor: pointer;
    color: white;
}

b {
    font-family: "Roboto-Bold";
    color: white;
}

i {
    font-family: "Roboto-Italic";
    color: white;
}

ul,
ol,
li {
    font-family: "Roboto-Medium";
    line-height: 1.6;
    font-size: 16;
}

div {
    font-family: "Roboto-Medium";
}

span {
    font-family: "Roboto-Medium";
}


/* 
* Text size 
*/
.xs-text {
    font-size: 8px !important;
}

.s-text {
    font-size: 12px !important;
}

.ms-text {
    font-size: 14px !important;
}

.m-text {
    font-size: 16px !important;
}

.l-text {
    font-size: 20px !important;
}

.xl-text {
    font-size: 24px !important;
}

/* 
* Text coloring 
*/
.black {
    color: black !important;
}
.white {
    color: white !important;
}

/* Grey */
.dark-grey {
    color: #6B6B6B !important;
}
.grey {
    color: #A1A1A1 !important;
}
.light-grey {
    color: #D6D6D6 !important;
}

/* Blue */
.dark-blue {
    color: #B9E5F3 !important;
}
.blue {
    color: #4FBFE0 !important;
}
.light-blue {
    color: #72CCE6 !important;
}

/* Yellow */
.dark-yellow {
    color: #BE8C24 !important;
}
.yellow {
    color: #FDBB30 !important;
}
.light-yellow {
    color: #FEE4AC !important;
}

/* Green */
.dark-green {
    color: #269900 !important;
}
.green {
    color: #33CC00 !important;
}
.light-green {
    color: #8FC981 !important;
}
.xlight-green {
    color: #ADEB99 !important;
}

/* Red */
.dark-red {
    color: #AA2820 !important;
}
.red {
    color: #FA3C57 !important;
}
.light-red {
    color: #EE8680 !important;
}

/* Orange */
.dark-orange {
    color: #B35026 !important;
}
.orange {
    color: #FF8533 !important;
}
.light-orange {
    color: #FFB685 !important;
}

/* Pink */
.dark-pink{
    color: #BB8F8D !important;
}
.pink {
    color: #F9BFBC !important;
}
.light-pink{
    color: #FBD9D7 !important;
}

/* Hover color */
.text-hover:hover {
    color: #4FBFE0  !important;
    cursor: pointer;
}

.text-hover-red:hover {
    color: #E3352B  !important;
    cursor: pointer;
}

.text-hover-green:hover {
    color: #33CC00  !important;
    cursor: pointer;
}

.text-hover-dark-green:hover {
    color: #269900  !important;
    cursor: pointer;
}

.text-hover-yellow:hover {
    color: #FDBB30  !important;
    cursor: pointer;
}

.text-hover-light-yellow:hover {
    color: #FEE4AC  !important;
    cursor: pointer;
}

.text-hover-pink:hover {
    color: #FF81CC  !important;
    cursor: pointer;
}

/* Misc */
.center-text {
    text-align: center !important; 
}

.anchor {
    font-size: 0;
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
 }

.thick-font {
    font-weight: 900;
}

.semi-thick-font {
    font-weight: 500;
}

.l-icon {
    font-size: 50px !important;
    padding: 10px;
}

.xl-icon {
    font-size: 75px !important;
    padding: 10px;
}






